@import url("../Fonts.css");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.burger-menu-container {
  position: relative;
  z-index: 2;
  height: 0;
}

.burger-menu {
  position: static;
  cursor: pointer;
  z-index: 99;
  display: inline-block
}

.burger-menu.open {
  z-index: 2;
}

.search-bar-container {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
}

.search-icon {
  margin-right: 8px;
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  background: transparent;
  border: none;
}

.overlay-all {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 30vw;
  height: 100vh;
  background-color: white;
  color: black;
  z-index: 3;
}

.menu {
  position: absolute;
  top: 0;
  width: 30vw;
  height: 100%;
  z-index: 4;
  transition: left 0.3s ease;
}

.menu.open {
  left: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.back-image {
  height: auto;
  width: 100vw;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

p {
  font-family: "Roboto-Regular";
}

h1,
h2,
h5,
b {
  font-family: "Roboto-Regular";
  font-weight: bold !important;
}

/*CSS*/
.menu-btn {
  display: block;
  margin-top: -30px;
  justify-content: center;
  align-items: center;
  position: relative;
  left: 75px;
  top: 80px;
  z-index: 1;
  cursor: pointer;
  width: 30px;
}

.btn-line {
  width: 100%;
  height: 2px;
  background: #000;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
}

/*Animate lines*/
.menu-btn.open .btn-line:nth-child(2) {
  opacity: 0;
}

.menu-btn.open .btn-line:nth-child(1) {
  transform: translateY(7px) rotate(45deg);
}

.menu-btn.open .btn-line:nth-child(3) {
  transform: translateY(-7px) rotate(-45deg);
}

@media screen and (max-width: 1200px) {
  /*CSS*/
  .menu-btn {
    display: block;
    margin-top: -20px;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 20px;
    top: 40px;
    z-index: 1;
    cursor: pointer;
    width: 15px;
  }

  .btn-line {
    width: 100%;
    height: 2px;
    background: #000;
    margin: 3px auto;
    transition: all 0.3s ease-in-out;
  }

  /*Animate lines*/
  .menu-btn.open .btn-line:nth-child(2) {
    opacity: 0;
  }

  .menu-btn.open .btn-line:nth-child(1) {
    transform: translateY(5px) rotate(45deg);
  }

  .menu-btn.open .btn-line:nth-child(3) {
    transform: translateY(-5px) rotate(-45deg);
  }
}
