.left-column {
  flex-basis: 50%;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-column:hover {
  background-color: #FFD000;
}

.left-content {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}

.left-content:hover {
  background-color: #FFD000;
}

.right-column {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
}

.right-row {
  flex-basis: 50%;
  background-color: #fafafa;
}

.right-row:hover {
  background-color: #FFD000;
}
