@font-face {
    font-family: 'Roboto-Regular';
    font-style: normal;
    font-weight: normal;
    src: url('../../public/assets/fonts/Roboto-Regular.ttf') format('truetype');
    /* Repeat the above line for each font file format you have (e.g., woff, woff2) */
}
  
@font-face {
    font-family: 'Roboto-Italic';
    font-style: italic;
    font-weight: normal;
    src: url('../../public/assets/fonts/Roboto-Italic.ttf') format('truetype');
    /* Repeat the above line for each font file format you have (e.g., woff, woff2) */
  }
  
  @font-face {
    font-family: 'Roboto-Bold';
    font-style: normal;
    font-weight: bold;
    src: url('../../public/assets/fonts/Roboto-Bold.ttf') format('truetype');
    /* Repeat the above line for each font file format you have (e.g., woff, woff2) */
  }

  @font-face {
    font-family: 'Roboto-BoldItalic';
    font-style: italic;
    font-weight: bold;
    src: url('../../public/assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
    /* Repeat the above line for each font file format you have (e.g., woff, woff2) */
  }
  
  @font-face {
    font-family: 'Roboto-Light';
    font-style: normal;
    font-weight: lighter;
    src: url('../../public/assets/fonts/Roboto-Light.ttf') format('truetype');
    /* Repeat the above line for each font file format you have (e.g., woff, woff2) */
  }
  
  @font-face {
    font-family: 'Roboto-LightItalic';
    font-style: italic;
    font-weight: lighter;
    src: url('../../public/assets/fonts/Roboto-LightItalic.ttf') format('truetype');
    /* Repeat the above line for each font file format you have (e.g., woff, woff2) */
  }
