@import url("../Fonts.css");

html {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

img {
  width: 100%;
  height: 400px;
}

.cover {
  object-fit: cover;
  overflow: hidden;
  width: 60%;
  /* height: auto; */
  /* float: left; */
}

body {
  width: 100%;
}

/* CSS dengan .head dan .text-over digunakan untuk tumpuk"an image/text/background */
.head-text {
  position: relative;
  height: 100px;
}

.head-image {
  height: auto !important;
  opacity: 1;
  text-align: left;
}

.head-background {
  background: #f6f4ea;
  opacity: 1;
}

.head-background:hover {
  background: linear-gradient(transparent, #fff1ad);
  opacity: 1;
}

.text-over {
  position: absolute;
  right: 5%;
  top: 350%;
}

/* IPAD */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .cover {
    object-fit: contain;
    overflow: hidden;
    width: 50%;
    /* float: left; */
  }

  img {
    height: 260px;
  }

  .text-over {
    top: 200%;
  }
}

/* iPad Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .cover {
    object-fit: contain;
    overflow: hidden;
    width: 50%;
    /* float: left; */
  }

  img {
    height: 260px;
  }

  .text-over {
    top: 200%;
  }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .cover {
    object-fit: contain;
    overflow: hidden;
    width: 100%;
    /* float: left; */
  }

  img {
    height: 260px;
  }

  .text-over {
    top: 200%;
  }
}

/* HP */
@media only screen and (max-width: 768px) {
  .cover {
    object-fit: contain;
    overflow: hidden;
    width: 70%;
    /* float: left; */
  }

  img {
    height: 150px;
  }

  .text-over {
    top: 100%;
    right: 10%;
  }
}
